
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component({
  model: {
    prop: "value",
    event: "change"
  }
})
export default class BtnCheckGroup extends Vue {
  @Prop({ default: "" })
  value: string;
  @Prop({
    default: () => []
  })
  checkOptions: any;
  @Prop({
    default: null,
    type: Boolean
  })
  disabled: boolean;
  handleCheckChange(option) {
    if (this.disabled || option.disable === true) return;
    if (option.value === this.value) {
      return;
    }
    this.$emit("change", option.value);
  }
  get $checkOptions() {
    return this.checkOptions;
  }
}
