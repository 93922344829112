
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import BtnCheckGroup from "@/components/scope/btn-check-group.vue";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import dayjs from "dayjs";
import { priceRulesEl, partsQuantityRules } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";

const parts = namespace("parts");
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
const inventory = namespace("inventory");
const serviceItem = namespace("service-item");
const plusTemplate = namespace("plusTemplate");
const management = namespace("management");
const marketing = namespace("marketing");



@Component({
  components: { FileUpload, BtnCheckGroup, GeneralVehicleSelect }
})
export default class ServiceItemEdit extends Vue {
  // @app.Mutation setPBPX;
  @management.Action getStoreList

  @serviceItem.Action createServiceItem;
  @serviceItem.Action updateServiceItem;
  @serviceItem.Action getopserviceitem;
  @serviceItem.Action getServiceDetail;
  @serviceItem.Action queryTwoLevelClassList;
  @parts.Action getPartsList;
  @parts.Action getPartsDetail;
  @user.State userData;
  @base.Action getDictionaryList;
  @inventory.Action saveInWarehouse;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @serviceItem.Action getServiceList;
  @plusTemplate.Action plusTemplateSave;
  @plusTemplate.Action getPlusTemplateDetail;
  @management.Action getStoreCouponList
  @marketing.Action queryCardTemplateList;

  dayjs = dayjs;
  splitThousands = splitThousands;

  get isAdd() {
    return this.$route.name === "plusCardTemplateAdd";
  }
  get breadData() {
    return [
      { name: "plus卡模板", path: "/plusCard/template/index" },
      {
        name: this.isAdd ? "新建模板" : "编辑模板"
      }
    ];
  }

  cardTypeList: [
    {
      label: "会员卡",
      value: 1
    },
    {
      label: "超级会员卡",
      value: 2
    }
  ]

  freeOrderLimitList = [
    {
      label: "无",
      value: 0
    },
    {
      label: "无门槛限制",
      value: 1
    },
    {
      label: "服务项限制",
      value: 2
    }
  ]

  benefitTypeList = [
    {
      label: "首次免单权益",
      value: 1,
      disabled: false
    },
    {
      label: "优惠券",
      value: 2,
      disabled: false
    },
    {
      label: "会员专享价",
      value: 3,
      disabled: false
    },
    {
      label: "超值免费服务",
      value: 4,
      disabled: false
    }
  ]

  distributionMethodList = [
    {
      label: "无",
      value: 0
    },
    {
      label: "自动发放",
      value: 1
    },
    {
      label: "手动领取",
      value: 2
    }
  ]


  benefitDefined: any = {
    tenantCode: "",
    orgCode: "",
    benefitType: "",
    benefitCode: "",
    benefitName: "",
    thumbnailUrl: "",
    price: "",
    quantity: "",
    description: "",
    remark: ""
  }

  benefitCategoryDefined: any = {
    benefitType: "",
    title: "",
    subTitle: "",
    icon: "",
    iconFile: "",
    descImage: "",
    descImageFile: "",
    benefitValue: "",
    discount: "",
    distributionMethod: "", //发放方式 0-无 1-自动发放 2-手动领取
    benefits: [],
    chooseStoreCode: "" // 选择的门店
  }


  plusTemplateForm: any = {
    plusTemplateCode: "",
    cardName: "",
    cardAmt: "",
    cardType: "",
    validPeriod: "",
    isFreeOrder: "",
    freeOrderLimit: "",
    plusLogo: "",
    defaultCardStyle: "",
    defaultBackgroundUrl: "",
    openCardStyle: "",
    backgroundUrl: "",
    detailCardStyle: "",
    description: "",
    remark: "",
    benefitCategorys: [],
    skuThumbnailUrl:""
  };

  defaultLogoList: any = []
  defaultCardStyleList: any = []
  defaultBackgroundList: any = []
  openCardStyleList: any = []
  openCardBackgroundList: any = []
  detailCardStyleList: any = []
  skuThumbnailList: any = []

  plusTemplateFormBaseRules: any = {
    cardName: [
      { required: true, message:"请填写名称"}
    ],
    cardAmt: [
      { required: true, message:"请填写金额"}
    ],
    cardType: [
      { required: true, message:"请选择卡类型"}
    ],
    validPeriod: [
      { required: true, message:"请填写有效期"}
    ],
    isFreeOrder: [
      { required: true, message:"请选择"}
    ],
    freeOrderLimit: [
      { required: true, message:"请选择"}
    ]
  }

  plusTemplateFormImageRules: any = {
    plusLogo: { required: true, validator: this.checkFrontImage },
    defaultCardStyle: { required: true, validator: this.checkFrontImage },
    defaultBackgroundUrl: { required: true, validator: this.checkFrontImage },
    openCardStyle: { required: true, validator: this.checkFrontImage },
    backgroundUrl: { required: true, validator: this.checkFrontImage },
    detailCardStyle: { required: true, validator: this.checkFrontImage }
  }
  checkFrontImage(rule, value, callback) {
    if (!value) {
      callback(new Error("请上传图片"));
    } else {
      callback();
    }
  }
  plusTemplateFormCategoryRules: any = {
    benefitType: [
      { required: true, message:"请选择类型"}
    ],
    title: [
      { required: true, message:"请填写标题"}
    ],
    subTitle: [
      { required: true, message:"请填写子标题"}
    ],
    benefitValue: [
      { required: true, message:"请填写权益值"}
    ],
    distributionMethod: [
      { required: true, message:"请选择发放方式"}
    ],
    icon: { required: true, validator: this.checkFrontImage },
    benefits: { required: true,  type:"array", min:1, message:"请添加权益", trigger:['change', 'blur']},
    benefitName: {required: true, message:"请选择权益", trigger:['blur']},
    price: {required: true, message:"请填写权益价值", trigger:['blur']},
    quantity: {required: true, message:"请填写权益数量", trigger:['blur']}
  }


  handleAddBenefitCategory() {
    this.plusTemplateForm.benefitCategorys.push(Object.assign({}, this.benefitCategoryDefined))
  }
  handleRemoveBenefitCategory(categoryIndex) {
    this.plusTemplateForm.benefitCategorys.splice(categoryIndex, 1);
    // this.validateParticipateLimit();
  }

  handleAddBenefit(categoryIndex) {
    let category = this.plusTemplateForm.benefitCategorys[categoryIndex];
    if (category.benefitType === "") {
        this.$message.error("请先选择权益类型");
        return;
    }
    this.plusTemplateForm.benefitCategorys[categoryIndex].benefits.push(Object.assign({}, this.benefitDefined, {benefitType: category.benefitType}));
  }

  handleRemoveBenefit(categoryIndex, benefitIndex) {
    this.plusTemplateForm.benefitCategorys[categoryIndex].benefits.splice(benefitIndex, 1);
  }

  handleChangeBenefitType(val, categoryIndex){
    this.disableBenefitType()
    this.plusTemplateForm.benefitCategorys[categoryIndex].benefits = [];
    this.searchBenefitItem("", categoryIndex);
  }

  disableBenefitType() {
    let usedType = [];
    this.plusTemplateForm.benefitCategorys.forEach(category => {
      usedType.push(category.benefitType);
    });
    console.log("usedType", usedType)
    this.benefitTypeList.forEach(item => {
      item.disabled = false;

      console.log(usedType.indexOf(item.value))

      if (usedType.indexOf(item.value) > -1) {
        item.disabled = true;
      }
    });
    console.log(this.benefitTypeList)
  }

  benefitItemList: any[] = [];
  searchBenefitItem(val, categoryIndex) {
    this.benefitItemList = []
    if (this.plusTemplateForm.benefitCategorys[categoryIndex].benefitType == 1
        || this.plusTemplateForm.benefitCategorys[categoryIndex].benefitType == 3) {
      this.getServiceItemLists(val);
    }else if (this.plusTemplateForm.benefitCategorys[categoryIndex].benefitType == 2) {
      this.getStoreCouponItemList(val, this.plusTemplateForm.benefitCategorys[categoryIndex].chooseStoreCode);
    } else if (this.plusTemplateForm.benefitCategorys[categoryIndex].benefitType == 4) {
      this.getCardTemplateItemList(val);
    }
  }

  handleChooseBenefitItem(val, categoryIndex, index) {
    let benefitList = this.plusTemplateForm.benefitCategorys[categoryIndex].benefits;
    benefitList.forEach(item => {
      if (item.benefitCode === val) {
        this.$message.error('已选择相同权益！');
      }
    })

    let benefit = this.plusTemplateForm.benefitCategorys[categoryIndex].benefits[index];
    this.benefitItemList.forEach(item => {
      if (item.benefitCode === val) {
        benefit.tenantCode = item.tenantCode;
        benefit.orgCode = item.orgCode;
        benefit.benefitCode = item.benefitCode;
        benefit.benefitName = item.benefitName;
        benefit.thumbnailUrl = item.thumbnailUrl;
        benefit.price = item.benefitPrice;
        benefit.description = item.description
      }
    });
    this.searchBenefitItem('', categoryIndex)
  }

  handleBenefitItemClear(categoryIndex, index) {
    this.searchBenefitItem('', categoryIndex)
    let category = this.plusTemplateForm.benefitCategorys[categoryIndex];
    this.plusTemplateForm.benefitCategorys[categoryIndex].benefits[index] =
        Object.assign({}, this.benefitDefined, {benefitType: category.benefitType})
  }

  getServiceItemLists(name) {
    return this.getServiceList({
      pageSize: 20,
      pageNum: 1,
      searchKey: name,
    }).then(data => {
      this.benefitItemList = data.data.list ? data.data.list.map(item => {
            item.tenantCode = "SYS_DEFAULT";
            item.orgCode = "SYS_DEFAULT"
            item.benefitCode = item.serviceCode;
            item.benefitName = item.serviceName;
            item.thumbnailUrl = item.imgUrl;
            item.benefitPrice = item.serviceFee;
            return item;
          }): []
    });
  }

  // 获取优惠券列表
  getStoreCouponItemList(name, storeCode){
    if (!storeCode) {
      return;
    }
    this.getStoreCouponList({
      storeCode: storeCode,
      activityName: name,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(res => {
      this.benefitItemList = res.data.list ? res.data.list.map(item => {
        // item.tenantCode = item.tenantCode;
        // item.orgCode = item.orgCode;
        item.benefitCode = item.activityCode;
        item.benefitName = item.activityName;
        // item.thumbnailUrl = item.thumbnailUrl;
        item.benefitPrice = 0;
        return item;
      }): []
    })
  }

  getCardTemplateItemList(name){
    let param = {
      cardTemplateName: name,
      enableStatus: 1, //（1：启用、0：禁用） 只显示启用的卡
      storeCode: '',
      cardType: 1 //（1：套餐卡、2：储蓄卡） 0630这个版本先写死
    }

    return this.queryCardTemplateList({
      ...param,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(res => {
      this.benefitItemList = res.data.list ? res.data.list.map(item => {
        item.tenantCode = "SYS_DEFAULT";
        item.orgCode = "SYS_DEFAULT";
        item.benefitCode = item.cardTemplateCode;
        item.benefitName = item.cardTemplateName;
        item.thumbnailUrl = item.icon;
        item.benefitPrice = item.carryingAmount;
        return item;
      }): []
    });
  }


  get plusTemplateCode() {
    return this.$route.params.plusTemplateCode;
  }
  submitForm() {
    let isSave = true;
    (this.$refs.plusTemplateFormBase as any).validate(valid => {
      if (!valid) {
        isSave = false;
      }
    });
    (this.$refs.plusTemplateFormImage as any).validate(valid => {
      if (!valid) {
        isSave = false;
      }
    });

    if(isSave) {
      this.plusTemplateSave({
        ...this.plusTemplateForm,
      }).then(res => {
        if(res.code==0){
          this.$router.go(-1);
        }else{
          this.$message.error('保存失败，请稍后重试！')
        }
      });
    };
  }

  emptyToLine = emptyToLine;


  created() {
    // this.setPBPX(52);
    this.init();
  }

  async init() {
    this.searchStore();
    if (!this.isAdd) {
      if(this.plusTemplateCode){
        this.getPlusTemplateDetail({plusTemplateCode: this.plusTemplateCode}).then(res => {
          Object.keys(res.data).forEach(key => {
            this.plusTemplateForm[key] = res.data[key];
          })
          this.defaultLogoList = [{url: res.data['plusLogo']}];
          this.defaultCardStyleList = [{url: res.data['defaultCardStyle']}];
          this.defaultBackgroundList = [{url: res.data['defaultBackgroundUrl']}];
          this.openCardStyleList = [{url: res.data['openCardStyle']}];
          this.openCardBackgroundList = [{url: res.data['backgroundUrl']}];
          this.detailCardStyleList = [{url: res.data['detailCardStyle']}];
          this.skuThumbnailList = [{url: res.data['skuThumbnailUrl']}];

          this.plusTemplateForm.benefitCategorys.forEach(category => {
            category.iconFile = [{url: category.icon}];
            category.descImageFile = [{url: category.descImage}];
          });

          this.disableBenefitType();
        })
      }
    }
  }

  storeList: any = []
  searchStore(val = ''){
    this.getStoreList({
      storeTypeList: ['3', '8', '9'],
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(res => {
      this.storeList = res.data.list?res.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
          : [];
    });
  }

  handleChooseStore(val, categoryIndex) {
    this.searchBenefitItem("", categoryIndex);
  }

  handleStoreClear(categoryIndex) {
    // this.plusTemplateForm.benefitCategorys[categoryIndex].benefits.splice(index, 1, Object.assign({}, this.benefitDefined));
  }
}
